@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
    font-family: 'Roboto';
    src: url('@assets/fonts/roboto/Roboto-Regular.ttf');
}

@font-face {
    font-family: 'Roboto';
    src: url('@assets/fonts/roboto/Roboto-Regular.ttf');
}

* {
    font-family: 'Roboto', sans-serif;
    margin: 0;
    padding: 0;
    line-height: initial;
}

*::-webkit-scrollbar {
    width: 8px !important;
    height: 8px !important;
    margin-bottom: 50px;
}

*::-webkit-scrollbar-thumb {
    background-color: var(--loglines-gray-v2);
    border-radius: 14px;
}

*::-webkit-scrollbar-thumb:hover {
    background-color: var(--loglines-gray-v3);
    border-radius: 15px !important;
}

::-webkit-scrollbar-thumb:horizontal {
    background-color: var(--loglines-gray-v2);
    border-radius: 14px;
}

::-webkit-scrollbar-thumb:horizontal:hover {
    background-color: var(--loglines-gray-v3);
    border-radius: 15px !important;
    cursor: pointer;
}

.scrollable-content::-webkit-scrollbar * {
    background: transparent;
}

.scrollable-content::-webkit-scrollbar-thumb {
    background: transparent;
}

body {
    color: var(--loglines-gray-v6);
}

/* @layer base {
    body {
        margin: 0;
        font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto',
        'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
        'Helvetica Neue', sans-serif;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        color: #424a58;
        font-size: 14px;

        tighter spacing
        @apply tracking-tighter;

        ::placeholder {
            @apply tracking-tighter;
            color: #aeb3bc !important;
        }

        ::-ms-input-placeholder {
            color: #aeb3bc !important;
        }
    }

    input:focus {
        box-shadow: none !important;
    }

    remove auto fill background color
    input:-webkit-autofill,
    input:-webkit-autofill:focus {
        transition:
            background-color 600000s 0s,
            color 600000s 0s;
    }
    input[data-autocompleted] {
        background-color: transparent !important;
    }

    code {
        font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
            monospace;
    }
} */

.form-line-price-table .table-wrapper {
    max-height: 200px;
    overflow-y: auto;
}

.form-line-price-table .table-wrapper table {
    width: 100%;
    border-radius: 5px;
}

.form-line-price-table .table-wrapper thead {
    background-color: var(--loglines-gray-v2);
    position: sticky;
    top: 0;
    z-index: 10;
}

.form-line-price-table .table-wrapper thead tr {
    font-size: var(--text-size-S);
    border-top-right-radius: 5px;
}

.form-line-price-table .table-wrapper thead tr td {
    padding: 12px 8px;
}

.form-line-price-table .table-wrapper thead tr td:first-child {
    border-top-left-radius: 5px;
    padding-left: 15px;
}

.form-line-price-table .table-wrapper thead tr td:last-child {
    border-top-right-radius: 5px;
    padding-left: 15px;
}

.form-line-price-table .table-wrapper tbody {
    background-color: var(--loglines-white);
    overflow-y: auto;
}

.form-line-price-table .table-wrapper tbody tr {
    font-size: var(--text-size-S);
    color: var(--loglines-gray-v5);
    border-bottom: 1px solid var(--loglines-gray-v2);
}

.form-line-price-table .table-wrapper tbody tr td {
    padding-top: 8px;
    padding-right: 8px;
}

.form-line-price-table .table-wrapper tbody tr td:first-child {
    padding-left: 15px;
}

@keyframes slide-in {
    0% {
        margin-top: -50px;
    }

    100% {
        margin-top: 0px;
    }
}

@keyframes slide-out {
    0% {
        margin-top: 0px;
    }

    100% {
        margin-top: -100px;
    }
}

/* Logistical Container */
.container-global {
    @apply border-solid border rounded-[10px];
    background-color: var(--loglines-white);
    border-color: var(--loglines-gray-v3);
}

.content-full-height {
    @apply h-[calc(100vh-6.5rem)] sm:!h-[calc(100vh-5.2rem)];
}

.box-shadow {
    box-shadow: 0px 10px 10px #cbcaca;
}

.required:before {
    content: ' *';
    color: var(--loglines-red-v3);
    padding-right: 1px;
}
