.table-expandable {
    border-color: var(--loglines-gray-v3);
    @apply rounded;
}

.tr-expandable {
    background-color: var(--loglines-white);
    border-color: var(--loglines-gray-v3);
    @apply transition;
}
.tr-expandable:hover {
    background-color: var(--loglines-gray-v2);
}
.thead-expandable {
    @apply sticky top-0 z-[5];
    background-color: var(--loglines-white);
    box-shadow: inset 0 -1px 0 var(--loglines-gray-v3);
}
.th-expandable:first-child {
    @apply !w-[50px];
}

.th-expandable {
    @apply text-left px-2 py-2 whitespace-nowrap w-auto font-normal;

    div {
        @apply h-[20px] flex flex-row items-center text-size-M;
        color: var(--loglines-gray-v5);
    }
}

.td-expandable {
    @apply px-2 py-2 whitespace-nowrap w-auto;
    div {
        @apply flex text-size-M;
    }
}

/* Child Area */
.tr-child {
    background-color: var(--loglines-gray-v1);
}
.tr-child.hide {
    @apply overflow-auto;
    @apply w-0;
}

.extendable {
    @apply transition-all duration-500 ease-in-out overflow-hidden;
}

.table-child {
    @apply w-full rounded;
    thead {
        background-color: white;
    }
    tr {
        border-color: var(--loglines-gray-v3);
        background-color: var(--loglines-white);
        @apply border-t;
    }
    td {
        @apply h-[20px];
    }
}
