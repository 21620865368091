.dropDown {
    @apply text-size-S rounded-[5px];
}

.dropdownParent-style {
    @apply border text-size-S rounded-[5px];
    color: var(--loglines-gray-v5);
    background-color: var(--loglines-white);
    /* border-color: var(--loglines-gray-v3); */
    position: relative;
}
.dropdownParent-style label {
    @apply w-fit absolute z-[1];
    @apply translate-x-2;
}

.dropdownLabelDiv-style {
    @apply w-fit;
    height: 3px;
    background-color: var(--loglines-gray-v1);
    padding-bottom: 0px;
    margin-top: -1px;
    padding-left: 2px;
    padding-right: 2px;
    display: flex;
}

.dropdownParent-style p {
    @apply w-fit;
    @apply text-size-XS;
    @apply -translate-y-2;
    color: var(--loglines-gray-v5);
}
[type='text']:focus,
[type='email'],
[type='url'],
[type='password'],
[type='number'],
[type='date'],
[type='datetime-local'],
[type='month'],
[type='search'],
[type='tel'],
[type='time'],
[type='week'],
[multiple],
textarea,
select {
    --tw-ring-color: transparent !important;
    --tw-ring-shadow: transparent !important;
}

/* overflow multi value */
.css-3w2yfm-ValueContainer {
    max-height: 99px;
    scrollbar-width: none;
}

.overflow-option .css-3w2yfm-ValueContainer {
    overflow: auto !important;
}

/* icon svg arrow */
.css-tj5bde-Svg {
    @apply cursor-pointer;
}

.css-1nmdiq5-menu {
    z-index: 99999 !important;
}

select.disabled {
    pointer-events: none;
    background-color: #ffffff; /* White color */
    border-color: inherit; /* Inherit the original border color */
}

.css-1ueq0fw-MenuList {
    border: none !important;
}

.select__menu {
    background-color: transparent;
    height: 10px;
}
