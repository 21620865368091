.heading {
    font-size: 16px;
    color: #465a6c;
}

.big-heading {
    font-size: 18px;
    color: #465a6c;
}

.sub-heading {
    font-size: 12px;
    color: #5b6c7c;
}

.text {
    font-size: 10px;
    color: #8d99a5;
}

.text-dark {
    font-size: 10px;
    color: #465a6c;
    font-weight: 400;
}

.text-size-xs {
    font-size: 8px;
    color: #8d99a5;
    font-weight: 600;
}

.custom-border-bottom-bold {
    border-bottom: 1px solid #8d99a5;
    padding-top: 4px;
    padding-bottom: 4px;
}

.custom-border-bottom-light {
    border-bottom: 0.5px solid #d9dde1;
    padding-top: 4px;
    padding-bottom: 4px;
}

.custom-border-top-light {
    border-top: 0.5px solid #d9dde1;
    padding-top: 4px;
    padding-bottom: 4px;
}

.custom-border-left-light {
    border-left: 0.5px solid #d9dde1;
    padding-top: 4px;
    padding-bottom: 4px;
}

.custom-border-right-light {
    border-right: 0.5px solid #d9dde1;
    padding-top: 4px;
    padding-bottom: 4px;
}

.custom-border-light {
    border: 0.5px solid #d9dde1;
    padding: 4px;
}

.custom-border-light-bottom {
    border-bottom: 0.5px solid #d9dde1;
    padding: 4px;
}

.bg-secondary {
    background-color: #f1f5f6;
}

td {
    padding-top: 2px;
    padding-bottom: 2px;
}
