.loglines-radio {
    background-color: var(--loglines-white);
    color: var(--loglines-brand-v5) !important;
    border-color: var(--loglines-gray-v3);
    @apply !ring-transparent;
}
.loglines-radio:focus {
    @apply !ring-transparent;
}
.loglines-radio:checked {
    background-color: var(--loglines-brand-v6);
    color: var(--loglines-brand-v6) !important;
}
