.textareaParent-style {
    @apply relative w-full;
}

.textareaParent-style label {
    @apply w-fit absolute;
    @apply translate-x-2;
}

.textareaParent-style .label-down {
    @apply w-fit absolute;
    @apply translate-x-2;
    bottom: 0px;
    right: 30px
}

.textareaParent-style div {
    @apply w-fit h-[2px] px-[2px];
    background-color: var(--loglines-white);
}

.textareaParent-style p {
    @apply w-fit;
    @apply text-size-XS;
    @apply -translate-y-2;
}

.textareaParent-style textarea {
    @apply h-full w-full p-2;
    @apply border rounded-logistical-radius;
    @apply text-size-S;
    background-color: var(--loglines-white);
    border-color: var(--loglines-gray-v3);
}
.textareaParent-style textarea:focus {
    border-color: var(--loglines-brand-v5);
    @apply !ring-transparent;
}

.textareaParent-style .disabled-gray {
    color: var(--loglines-gray-v6);
    background-color: var(--loglines-gray-v1);
}

.textareaParent-style .disabled-white {
    color: var(--loglines-gray-v5);
    background-color: var(--loglines-white);
}

.textareaParent-style textarea::placeholder {
    color: var(--loglines-gray-v4);
}
