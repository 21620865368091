.top-up-input {
    .inputParent-style {
        input {
            border: none;
            outline: none;
            font-size: 32px;
            font-weight: bold;
            background: transparent;
            width: 100%;
            text-align: right;
            color: var(--loglines-brand-v7) !important;
            &::placeholder{
                color: var(--loglines-brand-v2) ;
            }
        }
    }
}