.user-detail {
    @apply flex sm:flex-col gap-3;
    .content-1 {
        @apply flex w-full;
    }

    .content-1-A {
        @apply p-4 flex-initial !min-w-[400px] sm:!min-w-[0px] sm:w-[calc(100vw-1rem)];

        scrollbar-width: none;
        /* @apply  flex flex-col; */
    }
    /* .content-1-B {
        @apply p-4;
    } */

    .content-2 {
        @apply p-4 w-full !overflow-y-hidden content-baseline;
    }
}
