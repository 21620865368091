.switch {
    position: relative;
    display: inline-block;
    width: 38px;
    height: 20px;
}

.switch input {
    opacity: 0;
    width: 0;
    height: 0;
}

.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    /* background-color: #ccc; */
    background-color: var(--loglines-gray-v4);
    -webkit-transition: 0.4s;
    transition: 0.4s;
}

.slider:before {
    position: absolute;
    content: '';
    height: 12px;
    width: 12px;
    left: 4px;
    bottom: 4px;
    background-color: var(--loglines-white);
    -webkit-transition: 0.4s;
    transition: 0.4s;
}

input:checked + .slider {
    background-color: var(--loglines-brand-v5);
}

input:focus + .slider {
    box-shadow: 0 0 1px var(--loglines-brand-v5);
}

input:checked + .slider:before {
    -webkit-transform: translateX(17px);
    -ms-transform: translateX(17px);
    transform: translateX(17px);
}

/* Rounded sliders */
.slider.round {
    border-radius: 30px;
}

.slider.round:before {
    border-radius: 50%;
}
