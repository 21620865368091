.custom-text {
    color: #8d99a5;
    line-height: 1.2;
}

.heading {
    font-size: 20px;
    color: #8d99a5;
    font-weight: 400;
}

.sub-heading {
    font-size: 15px;
    color: #c0c7cd;
    font-weight: 400;
}

.text {
    font-size: 11px;
    color: #8d99a5;
    font-weight: 500;
}

.text-dark {
    font-size: 11px;
    color: #5b6c7c;
    font-weight: 600;
}

.text-size-xs {
    font-size: 8px;
}

.custom-border-bottom-bold {
    border-bottom: 1px solid #8d99a5;
    padding-top: 4px;
    padding-bottom: 4px;
}

.custom-border-bottom-light {
    border-bottom: 1px solid #d9dde1;
    padding-top: 4px;
    padding-bottom: 4px;
}

.custom-border-top-light {
    border-top: 2px solid #d9dde1;
    padding-top: 4px;
    padding-bottom: 4px;
}

.custom-border-light {
    border: 1px solid #d9dde1;
    padding: 4px;
}

.bg-secondary {
    background-color: #f5f6f7;
}
