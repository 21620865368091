body.dark-teal-themes {
    /* ------ Dynamic Loglines Colors ------ */
    --loglines-brand-v1: #e1f2f2;
    --loglines-brand-v2: #c3e6e6;
    --loglines-brand-v3: #83c6c6;
    --loglines-brand-v4: #438686;
    --loglines-brand-v5: #025152;
    --loglines-brand-v6: #014040;
    --loglines-brand-v7: #012c2c;
}
