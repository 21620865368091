.error-container {
    @apply h-full flex flex-col justify-center items-center;
    background-color: var(--loglines-white);
}

.table-container {
    @apply relative overflow-auto w-full;

    /* height */
    /* @apply h-[calc(100vh-230px)]; */
}

.table-component .dropdown label {
    @apply text-size-M;
}

.table-row {
    @apply relative my-2 overflow-auto w-max h-[calc(100vh-170px)] rounded;
    background-color: var(--loglines-white);
}

.table-master {
    @apply w-full;
}

.thead-master {
    @apply sticky top-0 z-[5];
    background-color: var(--loglines-white);
}

.thead-master .tr-master {
    @apply border-b-0;
    box-shadow: inset 0 -1px 0 var(--loglines-gray-v3);
}

.th-master:first-child,
.td-master:first-child {
    @apply pl-5;
}

.td-master {
    color: var(--loglines-gray-v6) !important;
    @apply px-2 h-[45px] whitespace-nowrap;
}

.td-master div {
    @apply h-full flex flex-row;
}

.tr-master {
    @apply border-b transition;
    background-color: var(--loglines-white);
}

.tr-master:hover {
    .td-master {
        background-color: var(--loglines-gray-v2);
    }
}

.tr-selected {
    .td-master {
        background-color: var(--loglines-brand-v2);
    }
}

.tr-selected:hover {
    .td-master {
        background-color: var(--loglines-brand-v3);
    }
}

.th-master {
    @apply text-left px-2 my-4 whitespace-nowrap h-10;
    font-weight: 350;
}

.th-master .checkboxEnable {
    width: 55px;
}

.th-master div {
    color: var(--loglines-gray-v5);
    @apply h-5 flex flex-row;
}

.edit-rename-column {
    @apply right-0 text-right;
}

.sort-active {
    color: var(--loglines-gray-v5);

    i {
        color: var(--loglines-gray-v5);
    }
}

/* checkbox sticky */
.sticky-column {
    position: sticky;
    left: 0;
    z-index: 1;
}

.sort-button {
    cursor: pointer;
    background-color: var(--loglines-gray-v1);
    @apply flex flex-col border-solid rounded ml-1 w-5 h-5;
}

.sort-button i {
    width: 100%;
    color: var(--loglines-gray-v5);
    @apply -mt-[3px];
}

.sort-button.border-color-active i {
    color: var(--loglines-gray-v7);
    text-align: center;
}

.border-color {
    border: solid 1px;
    border-color: var(--loglines-gray-v5);
}

.border-color-active {
    border: solid 1px;
    border-color: var(--loglines-gray-v7);
}
