.custom-input {
    border: 1px solid var(--loglines-gray-v3);
    border-radius: 5px;

    .dropdownParent-style {
        border: none;
    }
    .select__indicator-separator {
        display: none;
    }

    .inputParent-style input {
        border: none;
    }
}
